import { 
    useContext,
    useState
} from 'react';

import {
    Outlet,
    Navigate
} from "react-router-dom";

import {
    EditorContext,
    MembershipApplication
} from 'common/EditorContext';

import { AppContext } from 'common/AppContext';

/**
 * This provides a wrapper page for the authentication pages.
 * 
 * The routes are defined in the index page, where all pages are 
 * imported and wired up. The aim of this is to provide an overarching
 * template for the authentication pages.
 * 
 * Refer to the use of <Outlet> in the react-router documentation.
 * 
 * @returns {JSX.Element}
 */
function FrontOffice() {
    
    const appContext = useContext(AppContext);
    const [applicationForm, setApplicationForm] = useState<MembershipApplication>({
        membershipPlan: {
            priceId: null,
            productId: null,
            facilityId: null,
            catalogueId: null,
            facilityName: null,
            quantity: 1,
            price: 0,
            priceName: null,
            productName: null,
            productDescription: null,
            priceDescription: null,
            recurring: false,
            allowAddonMembers: false,
            officeNotes: "",
            startDate: new Date(),
        },
        paymentOption: {
            type: "card"
        },
        customer: {
            firstName: null,
            lastName: null,
            address: null,
            city: null,
            state: null,
            postCode: null,
            mobileNumber: null,
            email: null,
            snailMail: false,
            id: null
        },
        setupIntentId: null,
        selectedTab: 0,
        guestMembers: [],
    });
    return(
    (appContext && appContext.accessToken) ? (
    <section className="flex-1 w-full h-full py-8 mx-auto overflow-y-auto bg-white">
        <div className="max-w-screen-md mx-auto">
            <EditorContext.Provider value={applicationForm}>
                <Outlet/>
            </EditorContext.Provider>
        </div>
    </section>
    ) : <Navigate to="/auth/otp"/>);
}

export default FrontOffice;