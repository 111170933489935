import {
    ChevronRightIcon,
    MailIcon,
    PhoneIcon,
    PlusCircleIcon
} from "@heroicons/react/outline";
import {
    Link as RouterLink,
    useParams
} from "react-router-dom";
import { useContext, useEffect, useState } from 'react';

import {
    AppContext
} from 'common/AppContext';
import {
    CustomerResponse
} from "api/otc/models/CustomerResponse";
import { Helmet } from 'react-helmet';
import {
    parseISO
} from 'date-fns'

const MembershipOverview = () => {

    const appContext = useContext(AppContext);
    const [customers, setCustomers] = useState<CustomerResponse[]>([]);

    const { keywords } = useParams();

    useEffect(() => { 
        if(appContext?.accessToken && keywords) {
            appContext?.apiClient
            .customer.getAllCustomers(keywords)
            .then(response => {
                setCustomers(response);
            }).catch(error => {
    
            });    
        }
        
    }, [appContext, keywords]);

    return(
    <div className="flex-1 overflow-auto">
        <Helmet>
            <title>Overview</title>
        </Helmet>
        {customers.length === 0 && (
            <div className="flex flex-col items-center justify-center h-full">
                <p className="text-xl">No matching customers</p>
                <RouterLink 
                    className="flex items-center justify-center p-4 my-10 border-2 border-gray-400 border-dashed"
                    to="/staff/membership/customer/id">
                    <span className="mr-4">Add a new customer</span>
                    <PlusCircleIcon className="w-8 h-8"/>
                </RouterLink>
            </div>
        )}
        {customers.length > 0 && (
        <div className="mt-10 overflow-y-auto">
            <div className="max-w-screen-lg mx-auto">
            <div className="flex justify-between">
            <h1 className="mb-6 text-4xl font-bold text-gray-600">Search Results</h1>
            <RouterLink to="/staff/membership/customer/id">
                New Member
            </RouterLink>
            </div>
            <ul className="mb-10 overflow-auto text-gray-700 bg-white border-2 border-gray-100 rounded-lg shadow-lg">
                {customers.map((customer, i) => (
                <li key={customer.id}>
                    <RouterLink to={`/staff/membership/detail/${customer.id}`}  
                        className="flex items-center justify-between px-6 py-4 border-b border-gray-100">
                        <span className="">
                        <h2 className="text-xl font-bold">{customer.firstName} {customer.lastName}</h2>
                        <address className="not-italic">
                            {customer.address}<br/>
                            {customer.city}, ({customer.state}) {customer.postCode}
                        </address>
                        <ul className="mt-4">
                            <li className="flex items-center">
                                <PhoneIcon className="w-4 h-4 mr-4 text-gray-400" />
                                <span>{customer.mobileNumber}</span>
                            </li>
                            <li className="flex items-center">
                                <MailIcon className="w-4 h-4 mr-4 text-gray-400" />
                                <span>{customer.email}</span>
                            </li>
                        </ul>
                        </span>
                        <div className="flex items-center">
                            <span className="flex flex-col mr-8">
                                {
                                    customer && customer.memberships && customer.memberships.length > 0 && (
                                        <>
                                        <p className="font-semibold">{customer.memberships[0].productName}</p>
                                        <p className="font-semibold">{customer.memberships[0].planName}</p>
                                        <p className="text-sm text-gray-500">Expires {parseISO(customer.memberships[0].endDate).toLocaleDateString()}</p>
                                        </>
                                    )
                                }
                            <p className="flex items-center mt-2 text-sm">
                                {customer.cardHolder ? 
                                <span className="flex w-3 h-3 mr-2 bg-green-300 rounded-full"/>:
                                <span className="flex w-3 h-3 mr-2 bg-orange-300 rounded-full"/>}
                                Gallagher Token
                            </p>
                            </span>
                            <ChevronRightIcon className="w-6 h-6 text-gray-400" />
                        </div>
                    </RouterLink>
            </li>))}
            </ul>
            </div>
        </div>
        )}
    </div>);
}

export default MembershipOverview;  