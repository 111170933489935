/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CatalogueResponse } from '../models/CatalogueResponse';
import type { FacilityResponse } from '../models/FacilityResponse';
import type { ProductResponse } from '../models/ProductResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FacilityService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Facility Catalogues
     * Returns a list of Catagloues for a facility
     * @param facilityId
     * @returns CatalogueResponse Successful Response
     * @throws ApiError
     */
    public getFacilityCatalogues(
        facilityId: string,
    ): CancelablePromise<Array<CatalogueResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facility/{facility_id}/catalogue',
            path: {
                'facility_id': facilityId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Active Facility Catalogue
     * Returns the active catalogue for the facility
     * @param facilityId
     * @returns CatalogueResponse Successful Response
     * @throws ApiError
     */
    public getActiveCatalogueForFacility(
        facilityId: string,
    ): CancelablePromise<CatalogueResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facility/{facility_id}/catalogue/active',
            path: {
                'facility_id': facilityId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Product Form Catalogue
     * Returns the active catalogue for the facility
     * @param facilityId
     * @param catalogueId
     * @param productId
     * @returns ProductResponse Successful Response
     * @throws ApiError
     */
    public getProductForCatalogue(
        facilityId: string,
        catalogueId: string,
        productId: string,
    ): CancelablePromise<ProductResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facility/{facility_id}/catalogue/{catalogue_id}/product/{product_id}',
            path: {
                'facility_id': facilityId,
                'catalogue_id': catalogueId,
                'product_id': productId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Facilities
     * Returns a list of all facilities
     *
     * Facilities have a price catalgoue and users are able
     * to purchase a product tier to get access to a facility.
     * @returns FacilityResponse Successful Response
     * @throws ApiError
     */
    public getFacilities(): CancelablePromise<Array<FacilityResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/facility',
        });
    }

}
