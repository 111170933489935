import { createContext } from "react";  
import { AcaciaApiClient } from 'api/otc';

// Application level context
export interface AppContextInterface {
    apiClient: AcaciaApiClient;
    accessToken: string|null;
    refreshToken: string|null;
    isAdmin: boolean;
    isStaff: boolean;
}
  
export const AppContext = createContext<AppContextInterface | null>(null);
