/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardHolderResponse } from '../models/CardHolderResponse';
import type { CustomerPayload } from '../models/CustomerPayload';
import type { CustomerResponse } from '../models/CustomerResponse';
import type { MembershipPayload } from '../models/MembershipPayload';
import type { MembershipResponse } from '../models/MembershipResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new membership
     * @param id
     * @param requestBody
     * @returns MembershipResponse Successful Response
     * @throws ApiError
     */
    public createMembership(
        id: string,
        requestBody: MembershipPayload,
    ): CancelablePromise<MembershipResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customer/{id}/membership',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     *  Get the cardholder for the customer
     * Returns an error if there isn't one found
     *
     * Sends back the memberships for the customer
     * @param id
     * @returns CardHolderResponse Successful Response
     * @throws ApiError
     */
    public getCustomerCardholder(
        id: string,
    ): CancelablePromise<CardHolderResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customer/{id}/cardholder',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     *  Creates a new cardholder in the Gallagher command centre
     *
     * This endpoint should also link the cardholder to the customer in the
     * database, saving a second call to the server.
     *
     * Sends back a confirmation of creation of a card holder
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public createCardholderForCustomer(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customer/{id}/cardholder',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     *  Link a customer to a Gallagher cardholder
     *
     * Links a customer to a Gallagher cardholder
     * @param id
     * @param cardholderId
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public attachCardholderToCustomer(
        id: string,
        cardholderId: string,
    ): CancelablePromise<CustomerResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/customer/{id}/cardholder/{cardholder_id}',
            path: {
                'id': id,
                'cardholder_id': cardholderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create My Membership
     * Creates a new membership for the current user
     * @param requestBody
     * @returns MembershipResponse Successful Response
     * @throws ApiError
     */
    public createMyMembership(
        requestBody: MembershipPayload,
    ): CancelablePromise<MembershipResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customermy-membership',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer
     * Returns a list of all customers
     * @param search
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public getAllCustomers(
        search: string,
    ): CancelablePromise<Array<CustomerResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customer',
            query: {
                'search': search,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Customer
     * Creates a new customer
     * @param requestBody
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public createCustomer(
        requestBody: CustomerPayload,
    ): CancelablePromise<CustomerResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get My Customer
     * Returns the customer related to the logged in user
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public getMyCustomer(): CancelablePromise<CustomerResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customer/me',
        });
    }

    /**
     * Update My Customer
     * Returns the customer related to the logged in user
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateMyCustomer(
        requestBody: CustomerPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/customer/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Make My Customer
     * Returns the customer related to the logged in user
     * @param requestBody
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public makeMyCustomer(
        requestBody: CustomerPayload,
    ): CancelablePromise<CustomerResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customer/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer
     * Returns a list of all customers
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public getUnseenCustomers(): CancelablePromise<Array<CustomerResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customer/unseen',
        });
    }

    /**
     * Get Customer
     * Returns a single customer
     * @param id
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public getCustomer(
        id: string,
    ): CancelablePromise<CustomerResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customer/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Customer
     * Update an existing customer
     * @param id
     * @param requestBody
     * @returns CustomerResponse Successful Response
     * @throws ApiError
     */
    public updateCustomer(
        id: string,
        requestBody: CustomerPayload,
    ): CancelablePromise<CustomerResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/customer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Customer
     * Marks a Customer record as being seen by an admin
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public markCustomerAsRead(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/customer/{id}/read',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
