import { createContext } from "react";

export interface GuestMember {
    firstName: string | null;
    lastName: string | null;
}

export interface MembershipPlan {
    priceId: string | null;
    productId: string | null;
    catalogueId: string | null;
    facilityId: string | null;
    facilityName: string | null;   
    quantity: number;
    price: number;
    priceName: string | null;
    productName: string | null;
    productDescription: string | null;
    priceDescription: string | null;
    recurring: boolean;
    allowAddonMembers: boolean;
    officeNotes: string | null;
    startDate: Date;
}

export interface PaymentOption {
    type: string;
}

export interface Customer {
    firstName: string | null;
    lastName: string | null;
    address: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    mobileNumber: string | null;
    email: string | null;
    snailMail: boolean;
    id: string | null;
}
export interface MembershipApplication {
    customer: Customer;
    setupIntentId: string | null;
    selectedTab: number;
    paymentOption: PaymentOption;
    membershipPlan: MembershipPlan;
    guestMembers: GuestMember[];
}

export const EditorContext = createContext<MembershipApplication>({
    membershipPlan: {
        priceId: null,
        productId: null,
        facilityId: null,
        catalogueId: null,
        facilityName: null,
        quantity: 1,
        price: 0,
        priceName: null,
        productName: null,
        productDescription: null,
        priceDescription: null,
        recurring: false,
        allowAddonMembers: false,
        officeNotes: "",
        startDate: new Date(),
    },
    paymentOption: {
        type: "card"
    },
    customer: {
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        postCode: null,
        mobileNumber: null,
        email: null,
        snailMail: false,
        id: null
    },
    setupIntentId: null,
    selectedTab: 0,
    guestMembers: [],
});
