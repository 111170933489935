import { 
    useContext 
} from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { AppContext } from 'common/AppContext';
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
}).required();

function Login() {
    
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data:any) => {
        appContext?.apiClient.auth
        .loginUser(data)
        .then(response => { 
            if(appContext) {
                appContext.accessToken = response.accessToken;
                appContext.refreshToken = response.refreshToken;
            }
            navigate("/staff/membership", { replace: true });
        })
        .catch(error => { 
            
        });
    }

    return(
    <div>
        <Helmet>
            <title>Login</title>
        </Helmet>
        <form 
            className="p-4 mx-4 shadow-xl md:p-8 rounded-xl text-trc-blue bg-trc-brown"
            onSubmit={handleSubmit(onSubmit)}
        >
            <label className="block">
                <span className="text-gray-700">Email address</span>
                <input 
                    className="mt-2"
                    placeholder="e.g user@domain.com"
                    type="text" {...register("email")} />
            </label>
            <label className="block mt-4">
                <span className="text-gray-700">Password</span>
                <input 
                    className="mt-2"
                    placeholder=""
                    type="password" {...register("password")} />
            </label>
            <div className="flex justify-end col-span-2 mt-6">
                <input 
                    className="flex items-center justify-center w-full px-4 py-2 text-xl text-white rounded-lg bg-trc-blue"
                    type="submit" value="Login"
                />
            </div>

        </form>
    </div>);
}

export default Login;