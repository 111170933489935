import { 
    useContext 
} from 'react';

import {
    Outlet,
    Navigate
} from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import { AppContext } from "common/AppContext";

/**
 * This provides a wrapper page for the authentication pages.
 * 
 * The routes are defined in the index page, where all pages are 
 * imported and wired up. The aim of this is to provide an overarching
 * template for the authentication pages.
 * 
 * Refer to the use of <Outlet> in the react-router documentation.
 * 
 * @returns {JSX.Element}
 */
function Authentication() {

    const appContext = useContext(AppContext);

    return(
        (appContext && !appContext.accessToken) ? (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
            <Header/>
            <main>
                <Outlet />
            </main>
            <Footer/>
        </div>) : <Navigate to="/staff/membership"/>
    );
}

export default Authentication;