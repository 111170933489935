import {
    ArrowRightIcon,
    ChevronRightIcon
} from "@heroicons/react/solid";
import {
    useContext,
    useEffect,
    useState
} from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";

import {
    AppContext
} from 'common/AppContext';
import { CustomerResponse } from "api/otc";
import {
    EditorContext
} from "common/EditorContext";
import {
    FacilityResponse
} from 'api/otc/models/FacilityResponse';
import FailoverView from "common/FailoverView";
import DatePicker from 'react-date-picker';

function SelectFacility() {

    const { id } = useParams();

    const appContext = useContext(AppContext);
    const editorContext = useContext(EditorContext);
    const navigate = useNavigate();

    // Facilities loaded from the API
    const [facilities, setFacilities] = useState<FacilityResponse[]>([]);    
    // Selected facility by the user
    const [selectedFacility, setSelectedFacility] = useState<FacilityResponse>();
    // Date for the membership
    const [membershipDate, setMembershipDate] = useState<Date>(new Date());

    // Get the facilities when the app loads
    useEffect(() => { 
        if(id) {
            editorContext.customer.id = id;
        }
        appContext?.apiClient.facility
        .getFacilities()
        .then(response => { 
            setFacilities(response);
        }).catch(error => { 
            
        });
    }, [id]);

    // On selection set the context
    useEffect(() => {
        if(selectedFacility) {
            editorContext.membershipPlan.facilityId = selectedFacility.id;
            editorContext.membershipPlan.facilityName = selectedFacility.name;
        }
    }, [selectedFacility]);

    // Customer response from the API
    const [customer, setCustomer] = useState<CustomerResponse|null>(null);

    // Get the customer details from the API
    useEffect(() => {
        id && appContext?.apiClient
        .customer.getCustomer(id)
        .then(response => {
            setCustomer(response);
        }).catch(error => {

        });
    }, [id, appContext]);

    useEffect(() => {
        editorContext.membershipPlan.startDate = membershipDate;
    },[membershipDate]);

    // Update the context the moment we have customer and
    // render the component
    useEffect(() => {
        if(customer) {
            editorContext.customer.id = customer.id;
            editorContext.customer.firstName = customer.firstName;
            editorContext.customer.lastName = customer.lastName;
            editorContext.customer.address = customer.address;
            editorContext.customer.city = customer.city;
            editorContext.customer.state = customer.state;
            editorContext.customer.postCode = customer.postCode;
        }
        if(customer && customer.mobileNumber) {
            editorContext.customer.mobileNumber = customer.mobileNumber;
        }
        if(customer && customer.email) {
            editorContext.customer.email = customer.email;
        }
        if(customer && customer.snailMail) {
            editorContext.customer.snailMail = customer.snailMail;
        }
    }, [customer]);    


    // Navigation handlers
    const moveToNextPage = () => {
        navigate(`/staff/membership/add/${editorContext.customer.id}/product`);
    };

    const moveToPreviousPage = () => {
        navigate(`/staff/membership/detail/${editorContext.customer.id}`);
    }
    
    /**
     * Renders the facility selection component
     * 
     * An organisation has more than one facilities which in turn
     * has cataolgues.
     * 
     * @returns {JSX.Element}
     */
    const FacilityNames = () => {
        return(
        <ul className="grid w-full gap-6 mb-10 md:grid-cols-2">
        {facilities.map(facility => (
        <li key={facility.id}>
            <label>
                <input 
                    onChange={() => setSelectedFacility(facility)}
                    checked={selectedFacility?.id === facility.id}
                    type="radio" 
                    id={facility.id}
                    name="facility"
                    value={facility.id} 
                    className="hidden peer" required/>
                    <label 
                        htmlFor={facility.id} 
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-lime-500 peer-checked:border-lime-600 peer-checked:text-lime-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                    <div className="block">
                        <div className="w-full text-lg font-semibold">{facility.name}</div>
                        <div className="w-full">{facility.description}</div>
                    </div>
                    <ArrowRightIcon className="w-6 h-6 ml-3"/>
                </label>
            </label>
        </li>
        ))}
        </ul>);
    }
    
    return(
        <div className="">
        <h1 className="text-4xl font-bold text-gray-600">Choose a Facility</h1>
        <p className="my-6 ">Each customer can have memberships across all the facilities the organisation manages.</p>
        {facilities && customer && <FacilityNames/>}
        {facilities && (
            <div className="mb-8">
                <p className="text-2xl font-bold">Start Date</p>
                <p className="">You can custom pick the start date of the subscription</p>
                <DatePicker required={true} onChange={setMembershipDate} value={membershipDate} />
            </div>
        )}
        {!facilities && !customer && <FailoverView/>}
        <div className="flex justify-between">
        <input 
            type="submit" value="Back"
            onClick={moveToPreviousPage}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        />
        <input 
            disabled={!selectedFacility || !membershipDate}
            type="submit" value="Next"
            onClick={moveToNextPage}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        />
        </div>
        </div>);
}

export default SelectFacility;