import React from 'react';
import { 
    useState,
    useContext
} from 'react';
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
  
import { Tab } from "@headlessui/react";

function CardOrBank() {

    const stripe = useStripe();
    const elements = useElements();

    // Used to manage various UI states
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const [confirmation, setConfirmation] = useState(false);

    const handleSubmit = async (event:React.SyntheticEvent) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        // Show the spinner
        setLoading(true);
        
        const result = await stripe.confirmSetup({ elements,
            confirmParams: {
                return_url: ''
            }, 
            redirect: 'if_required'
        });

        setLoading(false);

        if(result.error) {
            setSuccess(false);
        }
        else {
            setConfirmation(true);
        }
    };

    return(
        <Tab.Panel className="py-8">
        <form onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button
                className="inline-flex items-center justify-center w-full px-4 py-2 mt-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                disabled={!stripe || loading}>
                {loading &&
                <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                }
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                Link to Customer
            </button>
            {!success &&
            <p className="px-4 py-2 my-4 font-bold text-center text-red-700 bg-red-100 rounded-full">There was an error linking the payment method</p>
            }
        </form>
        </Tab.Panel>
    );
}

export default CardOrBank;