/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessGroupResponse } from '../models/AccessGroupResponse';
import type { CardHolderResponse } from '../models/CardHolderResponse';
import type { CardTypeResponse } from '../models/CardTypeResponse';
import type { IssuedCardResponse } from '../models/IssuedCardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GallagherService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Searches a list of card holders
     * Sends back a filtered list of cardholders
     * @param keywords
     * @returns CardHolderResponse Successful Response
     * @throws ApiError
     */
    public getCardholders(
        keywords?: string,
    ): CancelablePromise<Array<CardHolderResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/gallagher/cardholder',
            query: {
                'keywords': keywords,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Searches a list of cards for a cardholder
     * @param id
     * @returns IssuedCardResponse Successful Response
     * @throws ApiError
     */
    public getCardholderCards(
        id: string,
    ): CancelablePromise<Array<IssuedCardResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/gallagher/cardholder/{id}/cards',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns a list of access groups
     * Sends back a list of Access Groups that the command centre has
     * @returns AccessGroupResponse Successful Response
     * @throws ApiError
     */
    public getAccessGroups(): CancelablePromise<Array<AccessGroupResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/gallagher/access-group',
        });
    }

    /**
     * Returns a list of card types
     * @returns CardTypeResponse Successful Response
     * @throws ApiError
     */
    public getCardTypes(): CancelablePromise<Array<CardTypeResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/gallagher/card-type',
        });
    }

}
