import { useContext, useEffect, useState } from 'react';
import { 
    Link as RouterLink,
} from "react-router-dom";
import { Helmet } from 'react-helmet';

import { 
    PhoneIcon,
    MailIcon,
    ChevronRightIcon,
    PlusCircleIcon
} from "@heroicons/react/outline";

import {
    AppContext
} from 'common/AppContext';
import { 
    CustomerResponse
} from "api/otc/models/CustomerResponse";
import MembershipAdmin from '.';

const UnseenMembers = () => {

    const appContext = useContext(AppContext);
    const [customers, setCustomers] = useState<CustomerResponse[]>([]);

    useEffect(() => { 
        appContext?.apiClient.customer
        .getUnseenCustomers().then(response => { 
            setCustomers(response);
        } ).catch(error => { 

        });
        
    }, [appContext]);

    return(
    <div className="flex-1 overflow-auto">
        <Helmet>
            <title>Overview</title>
        </Helmet>
        {customers.length === 0 && (
            <div className="flex flex-col items-center justify-center h-full">
                <p className="text-xl">No recent customer found, try searching</p>
                <RouterLink 
                    className="flex items-center justify-center p-4 my-10 border-2 border-gray-400 border-dashed"
                    to="/staff/membership/customer/id">
                    <span className="mr-4">Add a new customer</span>
                    <PlusCircleIcon className="w-8 h-8"/>
                </RouterLink>
            </div>
        )}
        {customers.length > 0 && (
        <div className="mt-10 overflow-y-auto">
            <div className="max-w-screen-lg mx-auto">
            <div className="flex items-center justify-between">
                <h1 className="mb-6 text-4xl font-bold text-gray-600">Recent Members</h1>
                <RouterLink 
                    to="/staff/membership/customer/id">
                    <PlusCircleIcon className="w-8 h-8"/>
                </RouterLink>
            </div>
            <ul className="mb-10 overflow-auto text-gray-700 bg-white border-2 border-gray-100 rounded-lg shadow-lg">
                {customers.map((customer, i) => (
                <li key={customer.id}>
                    <RouterLink to={`/staff/membership/detail/${customer.id}`}  
                        className="flex items-center justify-between px-6 py-4 border-b border-gray-100">
                        <span className="">
                        <h2 className="text-xl font-bold">{customer.firstName} {customer.lastName}</h2>
                        <address className="not-italic">
                            {customer.address}<br/>
                            {customer.city}, ({customer.state}) {customer.postCode}
                        </address>
                        <ul className="mt-4">
                            <li className="flex items-center">
                                <PhoneIcon className="w-4 h-4 mr-4 text-gray-400" />
                                <span>{customer.mobileNumber}</span>
                            </li>
                            <li className="flex items-center">
                                <MailIcon className="w-4 h-4 mr-4 text-gray-400" />
                                <span>{customer.email}</span>
                            </li>
                        </ul>
                        </span>
                        <div className="flex items-center">
                            <span className="flex flex-col mr-8">
                            {/* <p className="font-semibold">After Hours Membership</p>
                            <p className="text-sm text-gray-500">Expiring 13th December 2022</p> */}
                            <p className="flex items-center mt-2 text-sm">
                                {customer.cardHolder ? 
                                <span className="flex w-3 h-3 mr-2 bg-green-300 rounded-full"/>:
                                <span className="flex w-3 h-3 mr-2 bg-orange-300 rounded-full"/>}
                                Gallagher Token
                            </p>
                            </span>
                            <ChevronRightIcon className="w-6 h-6 text-gray-400" />
                        </div>
                    </RouterLink>
            </li>))}
            </ul>
            </div>
        </div>
        )}

    </div>);
}

export default UnseenMembers;