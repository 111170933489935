import {
    ChangeEvent,
    useState,
    useContext,
    useEffect
} from 'react';

import { 
    useDebounce 
} from 'usehooks-ts';

import { 
    PlusIcon,
} from "@heroicons/react/outline";


import { 
    AppContext
} from 'common/AppContext';

import { 
    CardHolderResponse 
} from 'api/otc/models/CardHolderResponse';

const CardHolderModal = ({ customerId }: any) => {

    const appContext = useContext(AppContext);

    const [value, setValue] = useState<string>('')
    const debouncedValue = useDebounce<string>(value, 500)

    // Results of the cardholders
    const [cardHolders, setCardHolders] = useState<CardHolderResponse[]>([]);

    // Handles the debounced value
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    const linkAccountToCustomer = (cardHolderId: string) => {
        appContext?.apiClient.customer
        .attachCardholderToCustomer(customerId, cardHolderId)
        .then(response => {
            console.log(response);
        }).catch(error => {
 
        });
    }
 
    useEffect(() => {
        if(debouncedValue.length === 0) {
            return 
        }
        appContext?.apiClient.gallagher.getCardholders(debouncedValue)
        .then(response => {
            setCardHolders(response);
        }).catch(error => {
 
        });
    }, [debouncedValue]);

    const makeNewCardholder = () => {
        appContext?.apiClient.customer
        .createCardholderForCustomer(customerId)
        .then(response => {
            console.log(response);
        }).catch(error => {
 
        });
    }

    return (
        <div className="flex flex-col">
            <input 
                type="text"
                placeholder="Search cardholders"
                className="mb-6"
                value={value}
                onChange={handleChange}
            />
            {cardHolders.length === 0 && <p className="flex items-center justify-center flex-1 h-full py-20">No cardholders found</p>}
            {cardHolders.length > 0 && (
            <ul>
                {cardHolders.map(cardHolder => (
                <li 
                    className="flex justify-between p-2"
                    key={cardHolder.id}>
                        <span>
                        {cardHolder.firstName} {cardHolder.lastName}<br/>
                        {cardHolder.gallagherId}
                        </span>
                        <button
                            onClick={() => linkAccountToCustomer(cardHolder.id)}
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:    ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Link
                        </button>
                </li>))}
            </ul>)}
            <button
                onClick={makeNewCardholder}
                className="flex flex-col items-center justify-center p-4 border-2 border-dashed rounded-lg border-trc-blue"
            >
                Make new card holder
                    <PlusIcon className="w-6 h-6"/>
            </button>
        </div>
    );
};

export default CardHolderModal;