/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientSecretResponse } from '../models/ClientSecretResponse';
import type { TerminalConnectionTokenResponse } from '../models/TerminalConnectionTokenResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StripeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Process Webhook
     * Response to webhooks from Stripe
     * @param stripeSignature
     * @returns any Successful Response
     * @throws ApiError
     */
    public processWebhookStripeWebhookPost(
        stripeSignature?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stripe/webhook',
            headers: {
                'STRIPE-SIGNATURE': stripeSignature,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     *  Returns a connection token for the terminal to work
     * Stripe terminals require a connection token to be generated
     *
     * Most of the integration will happen on the client side as
     * server side integration is limited to certain countries.
     * @returns TerminalConnectionTokenResponse Successful Response
     * @throws ApiError
     */
    public getTerminalConnectionToken(): CancelablePromise<TerminalConnectionTokenResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/terminal/connection-token',
        });
    }

    /**
     *  Returns a setup intent for a customer
     * Stripe related
     * @param customerId
     * @returns ClientSecretResponse Successful Response
     * @throws ApiError
     */
    public getSetupIntent(
        customerId: string,
    ): CancelablePromise<ClientSecretResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/setup-intent/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     *  Returns a payment intent for a customer, aimed to
     * be used for on site payments
     *
     * Stripe related
     * @param customerId
     * @returns ClientSecretResponse Successful Response
     * @throws ApiError
     */
    public getPaymentIntent(
        customerId: string,
    ): CancelablePromise<ClientSecretResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payments/payment-intent/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
