import { useContext, useState, useEffect } from 'react';
import { Tab } from '@headlessui/react'
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

// Stripe related stuff
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentElement from './CardOrBank';
import Cash from './Cash';
import Terminal from './Terminal';
import { AppContext } from 'common/AppContext';
import { EditorContext } from 'common/EditorContext';
import { useNavigate } from 'react-router-dom';
// Initialise Stripe and Terminal 
const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? 
  loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY):null;

const tabs = [
  { name: 'Cash', href: '#', current: true },
  { name: 'Card / Account', href: '#', current: false },
  { name: 'Terminal', href: '#', current: false },
]

function Payment() {

  const appContext = useContext(AppContext);
  const editorContext = useContext(EditorContext);
  const navigate = useNavigate();

  // Index of the selected tab
  const [paymentTypeIndex, setPaymentTypeIndex] = useState(0);
  const [clientSecret, setClientSecret] = useState("");

  const moveToNextPage = () => {
    navigate(`/staff/membership/add/${editorContext.customer.id}/confirmation`);
  };

  const moveToPreviousPage = () => {
    if(editorContext.membershipPlan.allowAddonMembers) {
      navigate(`/staff/membership/add/${editorContext.customer.id}/members`);
    }
    else {
      navigate(`/staff/membership/add/${editorContext.customer.id}/plan`);
    }

  }

  useEffect(() => { 
    if(editorContext) {
      switch(paymentTypeIndex) {
        case 0:
          // Cash
          editorContext.paymentOption.type = "cash";
          break;
        case 1:
          // Card / Account
          editorContext.paymentOption.type = "card";
          break;
        case 2:
          // Terminal
          editorContext.paymentOption.type = "terminal";
          break;
        default:
          editorContext.paymentOption.type = "cash";
      }
    }
    
  }, [paymentTypeIndex]);

  useEffect(() => {
    if(editorContext && editorContext.customer.id) {
      appContext?.apiClient.stripe
      .getSetupIntent(editorContext.customer.id)
      .then(intent => {
          setClientSecret(intent.clientSecret);
      }).catch(err => {
          
      });
    }

  },[editorContext.customer.id]);

  const appearance = {
      theme: 'stripe'
  };
  
  const options = {
      clientSecret,
  };

  return(
    <>
    <Helmet>
      <title>Payment Details</title>
    </Helmet>
    <h1 className="mb-6 text-4xl font-bold text-gray-600">How would they like to pay?</h1>
    {clientSecret && (
    <Elements stripe={stripePromise} options={options}>
    <Tab.Group selectedIndex={paymentTypeIndex} onChange={setPaymentTypeIndex}>
      <Tab.List className="flex justify-between">
      {tabs.map((tab, index) => (
        <Tab
          key={tab.name}
          className={clsx(
            index === paymentTypeIndex
              ? 'border-lime-500 text-lime-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
          )}
          aria-current={tab.current ? 'page' : undefined}
        >
          {tab.name}
        </Tab>
      ))}
      </Tab.List>
      <Tab.Panels className="w-full">
        <Cash/>
        <PaymentElement/>
        <Terminal/>
      </Tab.Panels>
      <nav className="flex justify-between">
          <button 
          type="submit" value="Back"
          onClick={moveToPreviousPage}
          className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
          >
              Back
          </button>
          <button
          onClick={moveToNextPage}
          className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
          >
              Next
          </button>
      </nav>
    </Tab.Group>
    </Elements>
    )}
    </>

  );

}

export default Payment;