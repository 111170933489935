import { 
    useContext,
    useState,
    useEffect,
    Fragment
} from "react";

import * as yup from "yup";

import { Helmet } from 'react-helmet';
import {
    AppContext
} from 'common/AppContext';
import {
    EditorContext
} from "common/EditorContext";
import { 
    yupResolver 
} from "@hookform/resolvers/yup";

import { 
    useForm,
    useFieldArray 
} from "react-hook-form";

import { EditorStepProps } from 'global/types';
import { useNavigate } from "react-router-dom";

const schema = yup
    .object()
    .shape({
        additional_members: yup.array().of(
            yup.object().shape({
                first_name: yup.string().required(),
                last_name: yup.string().required(),
            })
        ),
    })
    .defined();

const AdditionalMembers = () => {

    const appContext = useContext(AppContext);
    const editorContext = useContext(EditorContext);
    const navigate = useNavigate();

    const {
        register,
        reset,
        control,
        getValues,
        formState: { isValid },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "additional_members",
    });

    const handleClickMore = () => {
        append({});
    };

    const moveToNextPage = () => {
        let additionalMembers = getValues().additional_members;
        editorContext.guestMembers = additionalMembers;
        navigate(`/frontoffice/payment`);
    };

    const moveToPreviousPage = () => {
        navigate(`/frontoffice/plan`);
    }

    return(
    <>
        <Helmet>
            <title>Additional Details</title>
        </Helmet>
        <h1 className="mb-6 text-4xl font-bold text-gray-600">Additional members</h1>
        {!editorContext.membershipPlan.allowAddonMembers && (
        <div>
            <p>
                This membership does not allow additional members to be added to the application.
            </p>
            <p>
                Please click next to continue.
            </p>
        </div>)}
        {editorContext.membershipPlan.allowAddonMembers && (
        <div className="">
        <p>
            This membership allows additional individuals living under the same roof to be added
            to the application. Please click the plus button to add additional members.
        </p>
        {fields.map((field, index) => (
        <div
            key={index}
            className="grid grid-col1 mx-4 lg:mx-auto lg:grid-cols-[4fr,4fr,1fr] lg:w-full gap-4 mt-4"
        >
            <Fragment key={field.id}>
                <label className="block">
                    <span className="text-gray-700">First name</span>
                    <input
                        className="block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                        id={`additional_members_${index}_first_name`}
                        type="text"
                        {...register(
                            `additional_members.${index}.first_name`
                        )}
                    />
                </label>
                <label className="block">
                    <span className="text-gray-700">Last name</span>
                    <input
                        id={`additional_members_${index}_last_name`}
                        type="text"
                        {...register(
                            `additional_members.${index}.last_name`
                        )}
                    />
                </label>
                <button
                    className="flex items-center justify-center mt-6 font-bold text-white bg-red-500 rounded-md"
                    type="button"
                    onClick={() => {
                        remove(index);
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M18 12H6"
                        />
                    </svg>
                </button>
            </Fragment>
        </div>
        ))}
        <button
            className="flex items-center justify-center w-full p-4 my-10 border-2 border-dashed rounded-lg border-trc-blue"
            type="button"
            onClick={handleClickMore}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                />
            </svg>
        </button>
        </div>)}
        <div>
            <nav className="flex justify-between">
                <button 
                type="submit" value="Back"
                onClick={moveToPreviousPage}
                className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                >
                    Back
                </button>
                <button
                onClick={moveToNextPage}
                className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                >
                    Next
                </button>
            </nav>

        </div>
    </>
    );
}

export default AdditionalMembers;