import {
    useState
} from 'react';
import {
    Outlet
} from "react-router-dom";



/**
 * This provides a wrapper page for the authentication pages.
 * 
 * The routes are defined in the index page, where all pages are 
 * imported and wired up. The aim of this is to provide an overarching
 * template for the authentication pages.
 * 
 * Refer to the use of <Outlet> in the react-router documentation.
 * 
 * @returns {JSX.Element}
 */
function MembershipAdmin() {
    
    return(
        <Outlet />
    );
}

export default MembershipAdmin;