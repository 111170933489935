import React from 'react';
import ReactDOM from 'react-dom/client';
import { useContext } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './index.css';
import './i18n';

import reportWebVitals from './reportWebVitals';

import { 
  AcaciaApiClient 
} from 'api/otc';

import { 
  AppContext,
  AppContextInterface
} from 'common/AppContext';

import { 
  OpenAPI 
} from 'api/otc';

// Import views for the router to mount them
import App from './App';
import Authentication from './views/Authentication';
import Login from './views/Authentication/Login';
import OTP from './views/Authentication/OTP';
import SignUp from 'views/Authentication/SignUp';

import BackofficeRoot from 'views/BackOffice';
import MembershipAdmin from './views/BackOffice/Memberships';
import MembershipOverview from './views/BackOffice/Memberships/Overview';
import CustomerSummary from './views/BackOffice/Memberships/CustomerSummary';
import CustomerDetails from 'views/BackOffice/Memberships/CustomerDetails';
import UnseenMembers from 'views/BackOffice/Memberships/UnseenMembers';

import MembershipEditor from './views/BackOffice/Memberships/Editor';
import SelectFacility from './views/BackOffice/Memberships/Editor/SelectFacility';
import SelectProduct from 'views/BackOffice/Memberships/Editor/SelectProduct';
import SelectPlan from 'views/BackOffice/Memberships/Editor/SelectPlan';
import MembershipConfirmation from 'views/BackOffice/Memberships/Editor/Confirmation';
import MembershipPayment from 'views/BackOffice/Memberships/Editor/Payments';
import AdditionalMembers from 'views/BackOffice/Memberships/Editor/AdditionalMembers';
import OTPConfirm from './views/Authentication/OTPConfirm';
import FrontOffice from './views/FrontOffice';

import FESelectFacility from './views/FrontOffice/SelectFacility';
import FESelectProduct from './views/FrontOffice/SelectProduct';
import FESelectPlan from './views/FrontOffice/SelectPlan';
import FEPayment from './views/FrontOffice/Payment';
import FEConfirmation from './views/FrontOffice/Confirmation';
import FECustomerDetails from 'views/FrontOffice/Customer';
import FEThankyou from 'views/FrontOffice/Thankyou';
import FEMembers from 'views/FrontOffice/AdditionalMembers';

OpenAPI.HEADERS = async() => {
  return { };
}

const appContext: AppContextInterface = {
  apiClient: new AcaciaApiClient(OpenAPI),
  accessToken: null,
  refreshToken: null,
  isAdmin: false,
  isStaff: false
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppContext.Provider value={appContext}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<Authentication/>}>
            <Route path="login" element={<Login/>} />
            <Route path="otp" element={<OTP/>} />
            <Route path="otpconfirm/:mobileNumber" element={<OTPConfirm/>} />
            <Route path="signup" element={<SignUp/>} />
          </Route>
          <Route path="/frontoffice" element={<FrontOffice/>}>
            <Route path="customer" element={<FECustomerDetails/>}/>
            <Route path="facility" element={<FESelectFacility/>}/>
            <Route path="product" element={<FESelectProduct/>}/>
            <Route path="plan" element={<FESelectPlan/>}/>
            <Route path="members" element={<FEMembers/>}/>
            <Route path="payment" element={<FEPayment/>}/>
            <Route path="confirmation" element={<FEConfirmation/>}/>
            <Route path="thankyou" element={<FEThankyou/>}/>
          </Route>
          <Route path="/staff" element={<BackofficeRoot/>}>
            <Route path="/staff/membership" element={<MembershipAdmin/>}>
              <Route path="" element={<UnseenMembers/>} />          
              <Route path=":keywords" element={<MembershipOverview/>} />
              <Route path="customer/:id" element={<CustomerDetails/>} />
              <Route path="detail/:id" element={<CustomerSummary/>} />
              <Route path="add/:id" element={<MembershipEditor/>}>
                <Route path="facility" element={<SelectFacility/>}/>
                <Route path="product" element={<SelectProduct/>}/>
                <Route path="plan" element={<SelectPlan/>}/>
                <Route path="members" element={<AdditionalMembers/>}/>
                <Route path="payment" element={<MembershipPayment/>}/>
                <Route path="confirmation" element={<MembershipConfirmation/>}/>
              </Route>
            </Route>
          </Route>
          <Route path="/" element={<App />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
