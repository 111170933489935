import { 
    useContext,
    useEffect,
    useState,
    Fragment
} from 'react';
import { 
    useParams 
} from 'react-router-dom';
import {
    Link as RouterLink 
} from 'react-router-dom';
import {
    Dialog,
    Transition 
} from '@headlessui/react'
import { 
    PhoneIcon,
    MailIcon,
    PlusIcon,
    XIcon,
} from "@heroicons/react/outline";
import { 
  parseISO 
} from 'date-fns'
import { clsx } from 'clsx';

import {
    AppContext
} from 'common/AppContext';
import {
    CustomerResponse
} from "api/otc/models/CustomerResponse";
import CardHolderModal from './CardHolderModal';
import { IssuedCardResponse } from 'api/otc';

function CustomerSummary() {

    const appContext = useContext(AppContext);
    const [customer, setCustomer] = useState<CustomerResponse|null>(null);
    const [issuedCards, setIssuedCards] = useState<IssuedCardResponse[]>([]);
    const [open, setOpen] = useState(false)
    const [cardModalOpen, setCardModalOpen] = useState(false)

    // Get the ID from the parameter
    const { id } = useParams();

    useEffect(() => {
        id && appContext?.apiClient
        .customer.getCustomer(id)
        .then(response => {
            setCustomer(response);
        }).catch(error => {

        });
    }, [id]);

    // If we have a customer then get some other things
    useEffect(() => {
        customer && customer.cardHolder && appContext?.apiClient
        .gallagher.getCardholderCards(customer.cardHolder?.id)
        .then(response => {
            setIssuedCards(response);
        }).catch(error => {

        });
    }, [customer]);

    // Mark the customer as read, this should drop the
    // customer from the initial list
    useEffect(() => { 
        customer && appContext?.apiClient
        .customer.markCustomerAsRead(customer.id)
        .then(response => {
            // Do nothing
        }).catch(error => {

        });
    }, [customer]);

    const CardSlideover = () => {
        return (
            <Transition.Root show={cardModalOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setCardModalOpen}>
                <div className="fixed inset-0" />
        
                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                          <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                            <div className="px-4 sm:px-6">
                              <div className="flex items-start justify-between">
                                <Dialog.Title className="text-lg font-medium text-gray-900">Gallagher Card Holder</Dialog.Title>
                                <div className="flex items-center ml-3 h-7">
                                  <button
                                    type="button"
                                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setCardModalOpen(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon className="w-6 h-6" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex-1 px-4 mt-6 sm:px-6">
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
        )
    }   

    const CardHolderSlideover = () => {
        return (
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />
        
                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                          <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                            <div className="px-4 sm:px-6">
                              <div className="flex items-start justify-between">
                                <Dialog.Title className="text-lg font-medium text-gray-900">Gallagher Card Holder</Dialog.Title>
                                <div className="flex items-center ml-3 h-7">
                                  <button
                                    type="button"
                                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon className="w-6 h-6" aria-hidden="true" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex-1 px-4 mt-6 sm:px-6">
                              <CardHolderModal customerId={id}/>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
        )
    }


    const openTransactionModal = () => {
        setOpen(true);
    }

    const CardHolderFallback = () => {
        return(
        <>
        {customer && (
        <section className="flex flex-col justify-between max-w-screen-lg mx-auto my-10">
        <h2 className="text-3xl font-bold">Access Cards</h2>
        <button
            onClick={openTransactionModal} 
            className="flex items-center justify-center p-4 mt-4 border-2 border-dashed rounded-lg border-trc-blue">
            <span>
                Link or create a new Cardholder
            </span>
            <PlusIcon className="w-4 h-4" />
        </button>
        <p className="my-4 text-sm text-center">
            Before you can provision cards, you must make the customer a cardholder
        </p>
        </section>
        )}
        </>);
    }

    const CardList = () => {
        return(
        <>
        {customer && (
        <section className="flex flex-col justify-between max-w-screen-lg mx-auto my-10">
        <h2 className="text-3xl font-bold">Access Cards</h2>
        <ul className="grid grid-cols-3 gap-4 mt-6">
            {issuedCards.map((card, index) => (
            <li key={card.id} className="flex flex-col p-4 border rounded-lg border-trc-blue bg-trc-blue text-trc-brown">
                <h3 className="mb-10 text-xl font-bold text-white">{card.number}</h3>
                <div className="text-sm">
                    <p className="">{customer.email}</p>
                    <p className="">Invitation sent, {parseISO(customer.createdAt).toLocaleString()}</p>
                </div>
            </li>
            ))}
            <li className="flex">
              <button 
               onClick={() => setCardModalOpen(true)}
               className="flex flex-col items-center justify-center p-4 border-2 border-dashed rounded-lg border-trc-blue">
                <PlusIcon className="w-6 h-6"/>
              </button>
            </li>
        </ul>
        </section>
        )}
        </>);
    }

    const MembershipList = () => {
        return(
        <>
        {customer && (
        <section className="flex flex-col justify-between max-w-screen-lg mx-auto my-10">
        <h2 className="text-3xl font-bold">Memberships</h2>
        <ul className="grid grid-cols-1 gap-4 mt-6">
            {customer && customer.memberships && customer.memberships.map((membership, index) => (
            <li key={membership.id} className="flex justify-between p-4 border rounded-lg border-trc-blue bg-trc-blue text-trc-brown">
            <div className="flex">
                <div className="w-4 h-4 mt-1 mr-4 rounded-full bg-lime-400"/>
                <div className="flex flex-col">
                    <h3 className="mb-10 text-xl font-bold text-white">{membership.productName}</h3>
                    <div className="text-sm">
                        <p className="">{membership.planName}</p>
                        <p className="">Expires, {parseISO(membership.endDate).toLocaleString()}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-end justify-between">
                <p className="text-xl font-bold text-white">
                ${(membership.totalPaid/100).toLocaleString('en-au', 
                    {minimumFractionDigits: 2, 
                    maximumFractionDigits:2
                  })}
                </p>
                <p className="text-sm">Paid, {parseISO(membership.startDate).toLocaleString()}</p>
            </div>
            </li>
            ))}
            <li>
            <RouterLink to={`/staff/membership/add/${id}/facility`}
            className="flex flex-col items-center justify-center p-4 border-2 border-dashed rounded-lg border-trc-blue">
                <PlusIcon className="w-6 h-6"/>
            </RouterLink>
            </li>
        </ul>
        </section>
        )}
        </>);
    }

    const CustomerDetails = () => {
        return(
        <>
        {customer && (
        <section className="flex justify-between max-w-screen-lg px-6 py-4 mx-auto bg-white rounded-lg shadow-sm">
        <div>
            <h2 className="text-xl font-bold">{customer.firstName} {customer.lastName}</h2>
            <address className="not-italic">
                {customer.address}<br/>
                {customer.city}, ({customer.state}) {customer.postCode}
            </address>
            <ul className="mt-4">
                <li className="flex items-center">
                    <PhoneIcon className="w-4 h-4 mr-4 text-gray-400" />
                    <span>{customer.mobileNumber}</span>
                </li>
                <li className="flex items-center">
                    <MailIcon className="w-4 h-4 mr-4 text-gray-400" />
                    <span>{customer.email}</span>
                </li>
            </ul>
        </div>
        <div className="text-sm text-gray-500">
            <div className="grid grid-cols-2 gap-4">
                <p>Created:</p>
                <p>{parseISO(customer.createdAt).toLocaleString()}</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <p>Last updated</p>
                <p>{parseISO(customer.updatedAt).toLocaleString()}</p>
            </div>
        </div>
        </section>
        )}
        </>);
    }

    return(
    <section className="flex flex-col flex-1 overflow-auto">
        <div className="flex-1 mt-10 overflow-y-auto">
        {customer && (
        <>
        <CustomerDetails/>
        {!customer.cardHolder && <CardHolderFallback/>}
        {customer.cardHolder && <CardList/>}
        <MembershipList/>
        <CardHolderSlideover/>
        </>
        )}
        </div>
    </section>
    );
}

export default CustomerSummary;