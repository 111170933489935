import { 
    useContext 
} from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { AppContext } from 'common/AppContext';
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    reEnterPassword: yup.string().required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
}).required();

function SignUp() {
    
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data:any) => {
        appContext?.apiClient?.auth.signupUser(data)
        .then((response) => {
            navigate('/auth/login');
        })
        .catch((error) => {    

        });
    }

    return(
    <div>
        <Helmet>
            <title>Sign Up</title>
        </Helmet>
        <h1 className="mb-4 text-2xl font-bold text-center text-white">Sign Up for an account</h1>
        <form 
            className="p-4 mx-4 shadow-xl md:p-8 rounded-xl text-trc-blue bg-trc-brown"
            onSubmit={handleSubmit(onSubmit)}
        >
            <label className="block">
                <span className="text-gray-700">Email address</span>
                <input 
                    className="mt-2"
                    placeholder="e.g user@domain.com"
                    type="text" {...register("email")} />
            </label>
            <label className="block mt-4">
                <span className="text-gray-700">Password</span>
                <input 
                    className="mt-2"
                    placeholder=""
                    type="password" {...register("password")} />
            </label>
            <label className="block mt-4">
                <span className="text-gray-700">Re-enter password</span>
                <input 
                    className="mt-2"
                    placeholder=""
                    type="password" {...register("reEnterPassword")} />
            </label>
            <label className="block">
                <span className="text-gray-700">First name</span>
                <input 
                    className="mt-2"
                    placeholder="Joe"
                    type="text" {...register("firstName")} />
            </label>
            <label className="block">
                <span className="text-gray-700">Last name</span>
                <input 
                    className="mt-2"
                    placeholder="Bloggs"
                    type="text" {...register("lastName")} />
            </label>

            <div className="flex justify-end col-span-2 mt-6">
                <input 
                    className="flex items-center justify-center w-full px-4 py-2 text-xl text-white rounded-lg bg-trc-blue"
                    type="submit" value="Signup"
                />
            </div>

        </form>
    </div>);
}

export default SignUp;