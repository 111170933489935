import '@stripe/terminal-js';

import { DiscoverResult, ErrorResponse, Reader, loadStripeTerminal } from '@stripe/terminal-js';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'common/AppContext';
import { Tab } from "@headlessui/react";

function Terminal() {

    const appContext  = useContext(AppContext);

    // Load up the secrets for the Stripe Terminal
    const fetchConnectionToken = ():any => {
        const promise = appContext && appContext?.apiClient
        .stripe.getTerminalConnectionToken()
        .then(response => {
            return response.connectionToken;
        });
        return promise;
    }
    
    function unexpectedDisconnect() {
        // In this function, your app should notify the user that the reader disconnected.
        // You can also include a way to attempt to reconnect to a reader.
        console.log("Disconnected from reader")
    }

    async function triggerTerminal() {
        const StripeTerminal = await loadStripeTerminal();
        if(StripeTerminal) {
            var terminal = StripeTerminal.create({
                onFetchConnectionToken: fetchConnectionToken,
                onUnexpectedReaderDisconnect: unexpectedDisconnect,
            });

            console.log("Terminal", terminal);

            terminal.discoverReaders().then((result) => {
                let readers = result as DiscoverResult;

                console.log("Reader", readers);
                if(readers) {
                    let reader = readers.discoveredReaders[0];
                    terminal.connectReader(reader).then((connectionResult) => {
                        let errorResponse = connectionResult as ErrorResponse;
                        if(!errorResponse.error) {
                            let readerResponse = connectionResult as object;
                            console.log("Connected", readerResponse);
                        }
                        else {
                            console.log("Error", errorResponse);
                        }
                    })
                }
            })
        }
        else {
            console.log("No Terminal");
        }
    }

      

      
    return(
        <Tab.Panel className="py-8">
            <h1>Terminal</h1>
            <button onClick={triggerTerminal}>Trigger Terminal</button>
        </Tab.Panel>
    );
}

export default Terminal;