import {
    EditorContext,
    MembershipApplication
} from 'common/EditorContext';

import {
    Outlet
} from 'react-router-dom';
import {
    useState,
} from 'react';

function MembershipEditor() {

    const [applicationForm, setApplicationForm] = useState<MembershipApplication>({
        membershipPlan: {
            priceId: null,
            productId: null,
            facilityId: null,
            catalogueId: null,
            facilityName: null,
            quantity: 1,
            price: 0,
            priceName: null,
            productName: null,
            productDescription: null,
            priceDescription: null,
            recurring: false,
            allowAddonMembers: false,
            officeNotes: "",
            startDate: new Date(),
        },
        paymentOption: {
            type: "card"
        },
        customer: {
            firstName: null,
            lastName: null,
            address: null,
            city: null,
            state: null,
            postCode: null,
            mobileNumber: null,
            email: null,
            snailMail: false,
            id: null
        },
        setupIntentId: null,
        selectedTab: 0,
        guestMembers: [],
    });

    return(
    <section className="flex-1 w-full py-8 mx-auto overflow-y-auto">
        <div className="max-w-screen-md mx-auto">
            <EditorContext.Provider value={applicationForm}>
                <Outlet/>
            </EditorContext.Provider>
        </div>
    </section>
    );
}

export default MembershipEditor;