import { Link as RouterLink } from 'react-router-dom';
import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/solid";

import TSCLogo from 'assets/logo-trc.png';

function App() {

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen text-black app">
      <h1 className="sr-only">Temora Recreation Centre</h1>
      <img src={TSCLogo}
        alt="Temora Recreational Centre Logomark"
        width={150}
        height={150}
        className="mb-10"
      />
      <nav className="w-full px-4 md:px-0 md:max-w-screen-sm">
      <RouterLink to="/auth/otp">
        <div className="flex items-center justify-between w-full p-6 bg-white rounded-md text-trc-blue">
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold">Buy a new membership</h2>
            <p>Express checkout to get a new membership</p>
          </div>
          <ArrowRightIcon className="w-6 h-6 ml-8"/>
        </div>
      </RouterLink>
      {/* <RouterLink to="/auth/otp">
        <div className="flex items-center justify-between w-full p-6 mt-6 rounded-md text-trc-blue bg-trc-brown">
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold">Manage your membership</h2>
            <p>Add more subscriptions, manage digital or physical passes.</p>
          </div>
          <ArrowRightIcon className="w-6 h-6 ml-8"/>
        </div>
      </RouterLink> */}
      </nav>
      <footer className="mt-20 text-trc-brown">
        <p>&copy; Temora Shire Council</p>
      </footer>  
    </div>
);
}

export default App;
