import { 
    useContext,
    useState
} from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ArrowRightIcon } from '@heroicons/react/solid';
import { AppContext } from 'common/AppContext';

const schema = yup.object().shape({
    mobileNumber: yup.string().required(),
}).required();

function Login() {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data:any) => {
        
        // Don't do anything
        if(isLoading) return;

        setIsLoading(true);
        appContext?.apiClient.auth
        .initiateOtpViaSms(data)
        .then(response => { 
            setIsLoading(false);
            navigate(`/auth/otpconfirm/${data.mobileNumber}`);
        })
        .catch(error => { 
            setIsLoading(false);
            setError(true);
        });
    }

    return(
    <div className="p-4 mx-4 shadow-xl md:p-8 rounded-xl text-trc-blue bg-trc-brown">
        <Helmet>
            <title>Login via OTP</title>
        </Helmet>
        <h1 className="text-2xl font-semibold text-center">Get started</h1>
        <p className="text-center">
            We've made it easier than ever to manage your services.
        </p>
        <p className="text-center">
            Please enter your mobile number and we will<br/>send you a secure code to login.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
        <input 
            type="text" 
            placeholder="e.g 0499 229 339"
            className="my-10"
            {...register("mobileNumber")}></input>
        <input 
            type="submit"
            value="Send Code"
            className="flex items-center justify-center w-full px-4 py-2 text-xl text-white rounded-lg bg-trc-blue">
        </input>
        </form>
        {error && <p className="pt-4 font-bold text-center text-red-900">Unable to proceed at this time</p>}
    </div>);
}

export default Login;