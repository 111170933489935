import { Helmet } from 'react-helmet';

function FEThankyou() {

    return(
    <>
    <Helmet>
        <title>Customer Details</title>
    </Helmet>
    <div className="flex flex-col items-center justify-center mx-4 text-center md:mx-auto">
        <h1 className="mb-6 text-4xl font-bold text-gray-600">Thank you</h1>
        <p className="mb-6 text-2xl font-bold text-gray-600">Your payment has been processed</p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-green-500">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="mt-6 text-2xl text-gray-600">If your plan has after hours access,<br/>please check your email for access instructions.</p>
        <a className="mt-10 text-blue-600 underline" href="https://temora.nsw.gov.au">Return to Temora Shire Council</a>
    </div>
    </>);

}

export default FEThankyou;