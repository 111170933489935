import React from 'react';

function Footer() {
    return(
    <footer className="flex justify-center min-w-full m-10 text-trc-brown">
        <span>&copy; Temora Shire Council</span>
        <nav>
            
        </nav>
    </footer>);
}

export default Footer;