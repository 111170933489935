import { 
    useContext,
    useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ArrowRightIcon } from '@heroicons/react/solid';
import { AppContext } from 'common/AppContext';
import { Helmet } from "react-helmet";

const schema = yup.object().shape({
    otpCode: yup.string().required().length(6),
}).required();

function OTPConfirm() {

    const { mobileNumber } = useParams();

    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data:any) => {

        if(isLoading) return;
        
        setIsLoading(true);
        if(mobileNumber) {
            appContext?.apiClient.auth
            .verifyOtpAuthOtpVerifyPost({mobileNumber: mobileNumber, otp: data.otpCode})
            .then(response => { 
                setIsLoading(false);
                appContext.accessToken = response.accessToken;
                appContext.refreshToken = response.refreshToken;
                navigate('/frontoffice/customer')
            })
            .catch(error => { 
                setIsLoading(false);
                setError(true);                
            });    
        }
    }

    return(
    <div className="p-4 mx-4 shadow-xl md:p-8 rounded-xl text-trc-blue bg-trc-brown">
        <Helmet>
            <title>Confirm OTP</title>
        </Helmet>
        <h1 className="text-2xl font-semibold text-center">Enter your code</h1>
        <p className="text-center">
            Your should received an SMS with a six digit code.
        </p>
        <p className="text-center">
            Please enter that here within a minute.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
        <input 
            type="text" 
            placeholder="Your code"
            className="my-10"
            {...register("otpCode")}></input>
        <input 
            type="submit"
            value="Confirm"
            className="flex items-center justify-center w-full px-4 py-2 text-xl text-white rounded-lg bg-trc-blue">
        </input>
        </form>
        {error && <p className="pt-4 font-bold text-center text-red-900">Unable to proceed at this time</p>}
    </div>);
}

export default OTPConfirm;