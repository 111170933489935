import {
  ChangeEvent,
  useState,
  useEffect
} from 'react';

import { 
  useNavigate
} from 'react-router-dom';

import { 
  useDebounce 
} from 'usehooks-ts';

import TSCLogo from 'assets/logo-trc.png';

function Header() {

  const [value, setValue] = useState<string>('')
  const debouncedValue = useDebounce<string>(value, 500)
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    if(debouncedValue.length === 0) {
      return 
    }
    navigate(`/staff/membership/${debouncedValue}`);
  }, [debouncedValue]);

  return(
    <header className="flex justify-between min-w-full px-4 py-2 text-gray-700 bg-white border-b border-trc-brown">
      <img src={TSCLogo}
        alt="Temora Recreational Centre Logomark"
        width={60}
        height={60}
      />
      <input type="text" 
          placeholder="Search name, phone or address ..." 
          onChange={handleChange}
          className="max-w-screen-sm" />
      <nav>
        <ul className="flex justify-between">
          <li>User profile</li>
        </ul>
      </nav>
    </header>);
}

export default Header;