import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

// Stripe related stuff
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import FEPaymentElement from './CardOrBank';
import { AppContext } from 'common/AppContext';
import { EditorContext } from 'common/EditorContext';
import { useNavigate } from 'react-router-dom';

// Initialise Stripe and Terminal 
const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? 
  loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY):null;

function FEPayment() {

  const appContext = useContext(AppContext);
  const editorContext = useContext(EditorContext);
  const navigate = useNavigate();

  // Index of the selected tab
  const [paymentTypeIndex, setPaymentTypeIndex] = useState(0);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if(editorContext && editorContext.customer.id) {
      appContext?.apiClient.stripe
      .getSetupIntent(editorContext.customer.id)
      .then(intent => {
        console.log(intent);
          setClientSecret(intent.clientSecret);
      }).catch(err => {
          
      });
    }

  },[editorContext.customer.id]);

  const appearance = {
      theme: 'stripe'
  };
  
  const options = {
      clientSecret,
  };

  return(
    <div className="mx-4 md:mx-auto">
    <Helmet>
      <title>Payment Details</title>
    </Helmet>
    <h1 className="mb-6 text-4xl font-bold text-gray-600">How would you like to pay?</h1>
    {clientSecret && (
    <Elements stripe={stripePromise} options={options}>
        <FEPaymentElement/>
    </Elements>
    )}
    </div>

  );

}

export default FEPayment;