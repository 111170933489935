import {
    ArrowRightIcon,
    ChevronRightIcon
} from "@heroicons/react/solid";
import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";

import {
    AppContext
} from 'common/AppContext';
import {
    EditorContext
} from "common/EditorContext";
import FailoverView from "common/FailoverView";
import { PriceResponse } from "api/otc";
import { ProductResponse } from 'api/otc/models/ProductResponse';
import ReactMarkdown from 'react-markdown'

function SelectPlan() {

    const appContext = useContext(AppContext);
    const editorContext = useContext(EditorContext);
    const navigate = useNavigate();

    // Product response state
    const [product, setProduct] = useState<ProductResponse>();
    const [selectedPrice, setSelectedPrice] = useState<PriceResponse>();
    const [qty, setQty] = useState(1);

    useEffect(() => {
        if (editorContext &&
            editorContext.membershipPlan &&
            editorContext.membershipPlan.facilityId && 
            editorContext.membershipPlan.catalogueId && 
            editorContext.membershipPlan.productId) { 
            appContext?.apiClient.facility
            .getProductForCatalogue(editorContext.membershipPlan.facilityId, 
                editorContext.membershipPlan.catalogueId, 
                editorContext.membershipPlan.productId)
            .then(response => {
                setProduct(response);
            }).catch(error => { 
                
            });
        }
    }, [
        editorContext,
        editorContext.membershipPlan,
        editorContext.membershipPlan.facilityId,
        editorContext.membershipPlan.catalogueId,
        editorContext.membershipPlan.productId
    ]);

    // On selection set the price, update the context
    useEffect(() => {
        if(selectedPrice) {
            editorContext.membershipPlan.priceId = selectedPrice.id;
            editorContext.membershipPlan.priceName = selectedPrice.name;
            editorContext.membershipPlan.price = selectedPrice.price;
            editorContext.membershipPlan.priceDescription = selectedPrice.description;
            editorContext.membershipPlan.recurring = selectedPrice?.recurring || false;
            editorContext.membershipPlan.allowAddonMembers = selectedPrice?.allowsAddonMembers || false;
        }
        if(qty) {
            editorContext.membershipPlan.quantity = qty;
        }
    },[qty, selectedPrice]);

    const handleQtyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setQty(parseInt(e.target.value));
    }

    const moveToNextPage = () => {
        if(editorContext.membershipPlan.allowAddonMembers) {
            navigate(`/staff/membership/add/${editorContext.customer.id}/members`);
        }
        else {
            navigate(`/staff/membership/add/${editorContext.customer.id}/payment`);
        }
    };

    const moveToPreviousPage = () => {
        navigate(`/staff/membership/add/${editorContext.customer.id}/product`);
    }

    /**
     * 
     * @returns JSX.Element
     */
    const MonthSelector = () => {
        return(
        <div>
        <label className="inline-flex items-center justify-between w-full p-4 mt-4">
            <span className="flex-1 text-gray-700"><b>Number of months</b></span>
            <select 
                onChange={handleQtyChange}
                value={qty}
                className="block mt-1 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
            >
            {Array(12).fill(0).map((_, index) => <option key={index + 1} value={index + 1}>{index + 1}</option>)}
            </select>
        </label>
        </div>);
    }
        
   /**
     * Renders the membership products
     * 
     * @returns {JSX.Element}
     */
    const RenderMembershipProducts = () => {
        return(
        <ul className="grid w-full gap-6 md:grid-cols-1">
        {product && product.prices.map(price => (
        <li key={price.id}>
            <label>
                <input 
                    onChange={() => setSelectedPrice(price)}
                    checked={selectedPrice?.id === price.id}
                    type="radio" 
                    id={price.id}
                    name="price"
                    value={price.id} 
                    className="hidden peer" required/>
                    <label 
                        htmlFor={price.id} 
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-lime-500 peer-checked:border-lime-600 peer-checked:text-lime-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                    <div className="block">
                        <div className="w-full text-lg font-semibold">
                            <ReactMarkdown>
                            {price.name}
                            </ReactMarkdown>
                        </div>
                        <div className="w-full">
                            <ReactMarkdown>
                            {price.description}
                            </ReactMarkdown>
                        </div>
                    </div>
                    <ArrowRightIcon className="w-6 h-6 ml-3"/>
                </label>
            </label>
        </li>
        ))}
        </ul>);
    }
    
    return(
        <>
        <h1 className="text-4xl font-bold text-gray-600">Choose a Plan</h1>
        <p className="my-6">{editorContext && editorContext.membershipPlan.productName} provides the following price options:</p>
        {product && <RenderMembershipProducts/>}
        {!product && <FailoverView/>}
        {selectedPrice && selectedPrice.requiresQuantity && <MonthSelector/>}
        <div className="flex justify-between mt-6">
        <input 
            type="submit" value="Back"
            onClick={moveToPreviousPage}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        />
        <input 
            type="submit" value="Next"
            onClick={moveToNextPage}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        />
        </div>
        </>
    );
}

export default SelectPlan;