
function Footer() {
    return(
    <footer className="flex items-center justify-between px-4 py-2 text-sm border-t border-trc-brown bg-trc-blue text-trc-brown">
        <span>&copy; Temora Shire Council</span>
        <span className="flex items-center">
            <div className="w-3 h-3 mr-3 bg-green-400 rounded-full"/>
            <p>Gallagher Command Centre</p>
        </span>
    </footer>);
}

export default Footer;