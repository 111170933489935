import { useContext, useEffect, useState } from 'react';

import { EditorContext } from 'common/EditorContext';
import { Tab } from "@headlessui/react";

function Cash() {

    const formContext = useContext(EditorContext)

    const[dueAmount, _] = useState(formContext.membershipPlan.price*formContext.membershipPlan.quantity/100);
    const[paidAmount, setPaidAmount] = useState(0);
    const[amount, setAmount] = useState(dueAmount);
    const[change, setChange] = useState(false);
    const[officeNotes, setOfficeNotes] = useState("");

    useEffect(() => {
        // Fix the not a number problem
        if(isNaN(paidAmount)) {
            setPaidAmount(0);
        }
        if(paidAmount < dueAmount) {
            setAmount(dueAmount - paidAmount);
            setChange(false);
        }
        else {
            setAmount(paidAmount - dueAmount);
            setChange(true);
        }
        
    }, [paidAmount]);

    const handleMessageChange = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        setOfficeNotes(event.target.value);
    }

    useEffect(() => {
        formContext.membershipPlan.officeNotes = officeNotes;
    },[officeNotes]);
      
    return(
        <Tab.Panel className="py-8">
            <div className="flex items-center justify-between">
                <p className="text-xl font-medium">Amount due</p>
                <p className="text-3xl font-bold">$ {dueAmount.toLocaleString(undefined, 
                    {
                        maximumFractionDigits:2,
                        minimumFractionDigits:2})
                    }
                </p>
            </div>
            <div className="grid items-center grid-cols-2 mt-6">
                <p className="text-xl font-medium">Customer paid</p>
                <input 
                    onChange={(e) => { setPaidAmount(parseFloat(e.target.value)) }}
                    type="number"
                    className="px-4 py-2 text-4xl font-bold text-right bg-white border-2 border-gray-500 rounded "/>
            </div>
            <div className="flex items-center justify-between mt-6">
                <p className="text-xl font-medium">{change ? 'Change given':'Customer owes'}</p>
                <p className="text-3xl font-bold">$ {amount.toLocaleString(undefined, 
                    { 
                        maximumFractionDigits:2,
                        minimumFractionDigits:2})
                    }
                </p>
            </div>

            <div className="mt-10">
                <p className="mb-2 text-2xl font-bold">Office notes</p>
                <textarea 
                    value={officeNotes}
                    onChange={handleMessageChange}
                    rows={3} 
                    className="w-full bg-white border-2 border-gray-500 rounded"
                />
            </div>

        </Tab.Panel>
    );
}

export default Cash;