import { Link as RouterLink } from 'react-router-dom';

import TSCLogo from 'assets/logo-trc.png';

function Header() {
    return(
    <header className="flex justify-center min-w-full p-6 text-gray-700">
      <img src={TSCLogo}
        alt="Temora Recreational Centre Logomark"
        width={150}
        height={150}
        className="mb-10"
      />        
    </header>);
}

export default Header;