import {
    ArrowRightIcon,
    ChevronRightIcon
} from "@heroicons/react/solid";
import {
    CatalogueResponse,
    ProductResponse
} from "api/otc";
import React, {
    useContext,
    useEffect,
    useState,
} from "react";

import {
    AppContext
} from 'common/AppContext';
import {
    EditorContext
} from "common/EditorContext";
import FailoverView from "common/FailoverView";
import ReactMarkdown from 'react-markdown'
import {
    useNavigate,
} from "react-router-dom";

function SelectProduct() {

    const appContext = useContext(AppContext);
    const editorContext = useContext(EditorContext);
    const navigate = useNavigate();

    const [catalogue, setCatalogue] = useState<CatalogueResponse>();
    const [selectedProduct, setSelectedProduct] = useState<ProductResponse>();

    useEffect(() => { 
        if (editorContext &&
            editorContext.membershipPlan &&
            editorContext.membershipPlan.facilityId) { 
            appContext?.apiClient.facility
            .getActiveCatalogueForFacility(editorContext.membershipPlan.facilityId)
            .then(response => { 
                setCatalogue(response);
            }).catch(error => { 
                
            });
        }
    }, [
        editorContext,
        editorContext.membershipPlan,
        editorContext.membershipPlan.facilityId
    ]);

    useEffect(() => {
        if (selectedProduct) {
            editorContext.membershipPlan.productId = selectedProduct.id;
            editorContext.membershipPlan.productName = selectedProduct.name;
        }  
        if(selectedProduct && selectedProduct.description) {
            editorContext.membershipPlan.productDescription = selectedProduct.description;
        }
        if(catalogue) {
            editorContext.membershipPlan.catalogueId = catalogue.id;
        }
    }, [selectedProduct, catalogue]);

    const moveToNextPage = () => {
        navigate(`/staff/membership/add/${editorContext.customer.id}/plan`);
    };

    const moveToPreviousPage = () => {
        navigate(`/staff/membership/add/${editorContext.customer.id}/facility`);
    }

   /**
     * Renders the membership products
     * 
     * @returns {JSX.Element}
     */
    const RenderMembershipProducts = () => {
        return(
        <ul className="grid w-full gap-6 mb-10 md:grid-cols-1">
        {catalogue && catalogue.products && catalogue.products.map(product => (
        <li key={product.id}>
            <label>
                <input 
                    onChange={() => setSelectedProduct(product)}
                    checked={selectedProduct?.id === product.id}
                    type="radio" 
                    id={product.id}
                    name="product"
                    value={product.id} 
                    className="hidden peer" required/>
                    <label 
                        htmlFor={product.id} 
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-lime-500 peer-checked:border-lime-600 peer-checked:text-lime-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
                    <div className="block">
                        <div className="w-full text-lg font-semibold">
                            <ReactMarkdown>
                            {product.name}
                            </ReactMarkdown>
                        </div>
                        <div className="w-full">
                            <ReactMarkdown>
                            {product.description || ''}
                            </ReactMarkdown>
                        </div>
                    </div>
                    <ArrowRightIcon className="w-6 h-6 ml-3"/>
                </label>
            </label>
        </li>
        ))}
        </ul>);
    }
    
    return(
        <>
        <h1 className="text-4xl font-bold text-gray-600">Choose a Membership Product</h1>
        <p className="my-6">{editorContext && editorContext.membershipPlan.facilityName} provides the following membership options:</p>
        {catalogue && <RenderMembershipProducts/>}
        {!catalogue && <FailoverView/>}
        <div className="flex justify-between">
        <input 
            type="submit" value="Back"
            onClick={moveToPreviousPage}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        />
        <input 
            disabled={!selectedProduct}
            type="submit" value="Next"
            onClick={moveToNextPage}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        />
        </div>       
        </>)
};
 

export default SelectProduct;