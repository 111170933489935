import {
    MailIcon,
    PhoneIcon,
} from "@heroicons/react/outline";
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AppContext } from 'common/AppContext';
import { EditorContext } from 'common/EditorContext';
import FailoverView from "common/FailoverView";
import { Helmet } from 'react-helmet';
import { GuestMemberPayload } from "api/otc";

function Confirmation() {

    const appContext = useContext(AppContext);
    const editorContext = useContext(EditorContext);


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const onSubmit = () => {
        if(editorContext && 
            editorContext.membershipPlan && 
            editorContext.customer && 
            editorContext.customer.id &&
            editorContext.membershipPlan.priceId && 
            editorContext.membershipPlan.productId) {

            setLoading(true);
        
            appContext?.apiClient.customer.createMembership(editorContext.customer.id, {
                priceId: editorContext.membershipPlan.priceId,
                customerId: editorContext.customer.id,
                officeNotes: editorContext.membershipPlan.officeNotes || "",
                productId: editorContext.membershipPlan.productId,
                manual: (editorContext.paymentOption.type === "cash"),
                numberOfMonths: editorContext.membershipPlan.quantity,
                startDate: editorContext.membershipPlan.startDate.toISOString(),
                guestMembers: editorContext.guestMembers as GuestMemberPayload[]
            }).then(response => { 
                navigate(`/staff/membership/detail/${editorContext.customer.id}`);
            }).catch(error => { 
                setLoading(true);
                setError(true);
            });
        }
    }

    const CustomerSummary = () => {
        return(
        <span className="">      
        <h2 className="text-xl font-bold">{editorContext.customer.firstName} {editorContext.customer.lastName}</h2>
        <address className="not-italic">
            {editorContext.customer.address}<br/>
            {editorContext.customer.city}, ({editorContext.customer.state}) {editorContext.customer.postCode}
        </address>
        <ul className="mt-4">
            <li className="flex items-center">
                <PhoneIcon className="w-4 h-4 mr-4 text-gray-400" />
                <span>{editorContext.customer.mobileNumber}</span>
            </li>
            <li className="flex items-center">
                <MailIcon className="w-4 h-4 mr-4 text-gray-400" />
                <span>{editorContext.customer.email}</span>
            </li>
        </ul>
        </span>);
    }

    const PaymentMethodSummary = () => {
        return(
            <div className="flex justify-end">
                Paying by {editorContext.paymentOption.type}
            </div>
        );
    }

    const MembershipPlanSummary = () => {
        return(
        <span className="flex items-center justify-between col-span-2 pt-8">
            <div>
                <p className="text-2xl font-bold">{editorContext.membershipPlan.productName}</p>
                <p className="text-xl font-semibold">{editorContext.membershipPlan.priceName}</p>
            </div>
            {!editorContext.membershipPlan.recurring &&
            <div className="flex flex-col items-center">
                <p className="text-xl"> Upfont
                ${(editorContext.membershipPlan.price*editorContext.membershipPlan.quantity/100).toLocaleString('en-au', 
                    {minimumFractionDigits: 2, 
                    maximumFractionDigits:2
                })}
                </p>
                {editorContext.membershipPlan.quantity > 1 &&
                <p className="">covers {editorContext.membershipPlan.quantity} months</p>
                }
            </div>}
            {editorContext.membershipPlan.recurring &&
            <div className="flex items-center text-xl">
                {editorContext.membershipPlan.quantity > 1 &&
                <p className="mr-2">{editorContext.membershipPlan.quantity} payments of
                ${(editorContext.membershipPlan.price/100).toLocaleString('en-au', 
                    {minimumFractionDigits: 2, 
                    maximumFractionDigits:2
                })}
                </p>
                }
                {editorContext.membershipPlan.quantity === 1 &&
                <p className="mr-2">One payment of
                ${(editorContext.membershipPlan.price/100).toLocaleString('en-au', 
                    {minimumFractionDigits: 2, 
                    maximumFractionDigits:2
                })}
                </p>
                }
            </div>}
        </span>
        );
    }

    return(
    <>
        <Helmet>
            <title>Customer Details</title>
        </Helmet>
        <h1 className="mb-6 text-4xl font-bold text-gray-600">Everything look ok?</h1>
        <div className="grid grid-cols-2 gap-4">
            <CustomerSummary/>
            <PaymentMethodSummary/>
            <MembershipPlanSummary/>
        </div>
        <button
        onClick={onSubmit}
        disabled={loading}
        className="inline-flex items-center justify-center w-full px-4 py-2 mt-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        >
            {loading &&
            <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            }
            Start Membership
        </button>
        {error &&
        <p className="px-4 py-2 my-4 font-bold text-center text-red-700 bg-red-100 rounded-full">There was an error starting the membership</p>
        }
    </>);
}

export default Confirmation;