import { 
    useContext, useEffect
} from "react";
import {
    useNavigate,
} from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from "react-helmet";
import * as yup from "yup";

import { AppContext } from 'common/AppContext';
import { EditorContext } from 'common/EditorContext';

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    postCode: yup.string().required(),
    mobileNumber: yup.string(),
    email: yup.string().email().required(),
    snailMail: yup.boolean().required().default(false),
}).required();

function FECustomerDetails() {

    const appContext = useContext(AppContext);
    const editorContext = useContext(EditorContext);
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const copyToContext = (data:any) => {
        editorContext.customer.firstName = data.firstName;
        editorContext.customer.lastName = data.lastName;
        editorContext.customer.address = data.address;
        editorContext.customer.city = data.city;
        editorContext.customer.state = data.state;
        editorContext.customer.postCode = data.postCode;
        editorContext.customer.mobileNumber = data.mobileNumber;
        editorContext.customer.email = data.email;
        editorContext.customer.snailMail = false;
    };
    
    const onSubmit = async (data:any) => {

        // Copy the data from the form to the context
        copyToContext(data);

        if(editorContext && editorContext.customer.id) {
            appContext?.apiClient.customer
            .updateMyCustomer(data)
            .then(customer => {
                navigate(`/frontoffice/facility`);
            })
            .catch(error => {

            });
        }
        else {
            // Update the customer record
            appContext?.apiClient.customer
            .makeMyCustomer(data)
            .then(customer => {
                navigate(`/frontoffice/facility`);
            }).catch(error => {
                
            }); 
        }
    };

    useEffect(() => {
        appContext?.apiClient.customer
        .getMyCustomer().then(customer => {
            setValue('firstName', customer.firstName);      
            setValue('lastName', customer.lastName);      
            setValue('address', customer.address);      
            setValue('city', customer.city);      
            setValue('state', customer.state);
            setValue('postCode', customer.postCode);
            setValue('mobileNumber', customer.mobileNumber);
            setValue('email', customer.email);
            editorContext.customer.id = customer.id;
        }).catch(error => {
        })

    }, [appContext]);


    return(
    <div className="flex-1 mx-4 md:my-8 md:max-w-screen-lg md:mx-auto">
        <Helmet>
            <title>Your details</title>
        </Helmet>
        <h1 className="mb-6 text-4xl font-bold text-gray-600">Confirm your details</h1>
        <form 
            className="flex flex-col gap-6 placeholder-gray-300 md:grid md:grid-cols-2"
            onSubmit={handleSubmit(onSubmit)}
        >
            <label className="block col-span-2">
                <span className="text-gray-700">Email address</span>
                <input 
                    placeholder="e.g cate@marvel.com"
                    className="block w-full mt-1 placeholder-gray-300 border-2 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                    type="email" {...register("email")} />
            </label>
            <label className="block">
                <span className="text-gray-700">First name</span>
                <input
                    placeholder="e.g Cate"
                    className="block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                    type="text" {...register("firstName")} />
            </label>
            <label className="block">
                <span className="text-gray-700">Last name</span>
                <input 
                    placeholder="e.g Shortland"
                    className="block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                    type="text" {...register("lastName")} />
            </label>
            <label className="block col-span-2">
                <span className="text-gray-700">Street address</span>
                <input 
                    placeholder="e.g 105 Loftus Street"
                    className="block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                    type="text" {...register("address")} />
            </label>
            <label className="block">
                <span className="text-gray-700">City / Town</span>
                <input 
                    placeholder="e.g Temora"
                    className="block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                    type="text" {...register("city")} />
            </label>
            <label className="block">
                <span className="text-gray-700">State</span>
                <select 
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50 "
                    {...register("state")}>
                    <option value="NSW">NSW</option>
                    <option value="ACT">ACT</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                </select>
            </label>
            <label className="block">
                <span className="text-gray-700">Postcode</span>
                <input 
                    placeholder="e.g 2650"
                    className="block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
                    type="text" {...register("postCode")} />
            </label>
            <div className="flex justify-end col-span-2">
                <input 
                    type="submit" value="Next"
                    className="inline-flex items-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm cursor-pointer bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                />
            </div>
        </form>
    </div>);
}

export default FECustomerDetails;