import { 
    useContext 
} from 'react';
import { 
    Outlet,
    Navigate
} from 'react-router-dom';

import { AppContext } from 'common/AppContext';
import Header from "./Header";
import Footer from "./Footer";

function BackofficeRoot() {

    const appContext = useContext(AppContext);

    return(
    (appContext && appContext.accessToken) ? (
    <div className="flex flex-col w-full h-full bg-gray-50">
        <Header/>
        <Outlet/>
        <Footer/>
    </div>
    ) : <Navigate to="/auth/login"/>);
}

export default BackofficeRoot;